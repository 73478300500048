import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { verifyPaystackTransaction } from "../services/paymentService";

function PaymentCallback() {
  const location = useLocation();
  const [message, setMessage] = useState("Verifying your payment...");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const reference = params.get("reference");

    if (reference) {
      verifyPaystackTransaction(reference)
        .then(response => {
          if (response.status === "success") {
            setMessage(`Payment successful! Transaction ref: ${response.data.reference}`);
            // Optionally update your database here if needed
          } else {
            setMessage("Payment verification failed. Please contact support.");
          }
        })
        .catch(error => {
          console.error("Verification error", error);
          setMessage("An error occurred while verifying payment.");
        });
    } else {
      setMessage("No reference found in the URL. Unable to verify payment.");
    }
  }, [location.search]);

  return (
    <div>
      <h1>Payment Status</h1>
      <p>{message}</p>
    </div>
  );
}

export default PaymentCallback;
