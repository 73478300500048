import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import "./CoverOptions.css";

const mainLifeCover = [
  {
    type: "Silver",
    features: [
      { title: "Life Cover", value: "Gh₵ 1,500" },
      { title: "Disability (Accident)", value: "GH₵ 3,000" },
      { title: "Hospitalization Benefit", value: "GH₵ 50/day" },
      { title: "Monthly Premium", value: "GH₵ 4.32" },
      { title: "Annual Premium", value: "GH₵ 51.84" },
    ],
    monthly: 4.32,
    annual: 51.84,
  },
  {
    type: "Gold",
    features: [
      { title: "Life Cover", value: "Gh₵ 2,500" },
      { title: "Disability (Accident)", value: "GH₵ 5,000" },
      { title: "Hospitalization Benefit", value: "GH₵ 100/day" },
      { title: "Monthly Premium", value: "GH₵ 8.07" },
      { title: "Annual Premium", value: "GH₵ 96.79" },
    ],
    monthly: 8.07,
    annual: 96.79,
  },
  {
    type: "Platinum",
    features: [
      { title: "Life Cover", value: "Gh₵ 5,000" },
      { title: "Disability (Accident)", value: "GH₵ 10,000" },
      { title: "Hospitalization Benefit", value: "GH₵ 200/day" },
      { title: "Monthly Premium", value: "GH₵ 16.13" },
      { title: "Annual Premium", value: "GH₵ 193.58" },
    ],
    monthly: 16.13,
    annual: 193.58,
  },
];

const tier1Data = [
  {ageBandLabel: "1-69 years", monthly: 2.63, annual: 31.58},
  {ageBandLabel: "70-74 years", monthly: 13.25, annual: 159.02},
  {ageBandLabel: "75-84 years", monthly: 24.18, annual: 290.16},
];

const tier2Data = [
  {ageBandLabel: "1-69 years", monthly: 5.26, annual: 63.15},
  {ageBandLabel: "70-74 years", monthly: 26.50, annual: 318.03},
  {ageBandLabel: "75-84 years", monthly: 48.36, annual: 580.32},
];

const CoverOptions = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [progressStep, setProgressStep] = useState(1);
  const tierSectionRef = useRef(null);

  const planObj = mainLifeCover.find(p => p.type === selectedPlan);
  const mainMonthly = planObj ? planObj.monthly : 0;
  const mainAnnual = planObj ? planObj.annual : 0;

  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedAgeBand, setSelectedAgeBand] = useState(null);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan.type);
    setProgressStep(2);
    setTimeout(() => {
      tierSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  const getTierData = (tier) => tier === 'tier1' ? tier1Data : tier2Data;
  const tierMonthly = selectedTier && selectedAgeBand !== null ? getTierData(selectedTier)[selectedAgeBand].monthly : 0;
  const tierAnnual = selectedTier && selectedAgeBand !== null ? getTierData(selectedTier)[selectedAgeBand].annual : 0;

  const totalMonthly = mainMonthly + tierMonthly;
  const totalAnnual = mainAnnual + tierAnnual;

  const canConfirm = selectedPlan && selectedTier && selectedAgeBand !== null;

  let tierLabel = '';
  let tierNameForURL = '';
  if (selectedTier === 'tier1') {
    tierLabel = 'Tier 1';
    tierNameForURL = 'tier1';
  } else if (selectedTier === 'tier2') {
    tierLabel = 'Tier 2';
    tierNameForURL = 'tier2';
  }

  let ageGroupLabel = '';
  if (selectedTier && selectedAgeBand !== null) {
    ageGroupLabel = getTierData(selectedTier)[selectedAgeBand].ageBandLabel;
  }

  return (
    <>
      <Header />
      <div className="progress-bar-container" style={{top:'71px'}}>
        <div className="progress-steps">
          <span className={`progress-step ${progressStep >= 1 ? 'active' : ''}`}>1. Your Plan</span>
          <span className={`progress-step ${progressStep >= 2 ? 'active' : ''}`}>2. Beneficiary</span>
        </div>
        <div className="progress-line">
          <div className="progress-line-fill" style={{width: `${progressStep === 1 ? '50%' : '100%'}`}}></div>
        </div>
      </div>

      <div className="cover-options-page">
        <div className="cover-options-container">
          <h1 className="page-title">Your insurance plan</h1>
          <p className="page-subtitle">
            Choose the perfect plan to secure your future.
          </p>

          <div className="pricing-grid-container">
            <div className="pricing-grid">
              {mainLifeCover.map((plan, index) => (
                <div className="pricing-card" key={index}>
                  <div className="card-content">
                    <h3 className="plan-type">{plan.type}</h3>
                    <ul className="features-list">
                      {plan.features.map((feature, i) => (
                        <li key={i}>
                          <span className="feature-title">{feature.title}</span>
                          <span className="feature-value">{feature.value}</span>
                        </li>
                      ))}
                    </ul>
                    <button 
                      onClick={() => handlePlanSelect(plan)} 
                      className="select-button plan-btn-top"
                    >
                      Select Plan
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {selectedPlan && (
          <div style={{textAlign:'center', marginTop:'2rem'}}>
            <div className="down-arrow" onClick={() => tierSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
              ↓
            </div>
          </div>
        )}

        <div ref={tierSectionRef}></div>

        {selectedPlan && (
          <div className="beneficiary-section">
            <div className="beneficiary-left">
              <h1 className="section-heading">1. Select your beneficiary’s coverage level</h1>
              <div className="option-card" onClick={() => setSelectedTier('tier1')}>
                <div className="option-card-inner">
                  <input type="radio" checked={selectedTier === 'tier1'} readOnly />
                  <div className="option-card-content">
                    <h3>Tier 1</h3>
                    <p>Base coverage with Life Cover of Gh₵ 1,000</p>
                  </div>
                </div>
              </div>
              <div className="option-card" onClick={() => setSelectedTier('tier2')}>
                <div className="option-card-inner">
                  <input type="radio" checked={selectedTier === 'tier2'} readOnly />
                  <div className="option-card-content">
                    <h3>Tier 2</h3>
                    <p>Enhanced coverage with Life Cover of Gh₵ 2,000</p>
                  </div>
                </div>
              </div>

              {selectedTier && (
                <>
                  <h1 className="section-heading" style={{marginTop:'2rem'}}>2. Select your beneficiary’s age group</h1>
                  {getTierData(selectedTier).map((band, idx) => (
                    <div className="option-card" key={idx} onClick={() => setSelectedAgeBand(idx)}>
                      <div className="option-card-inner">
                        <input type="radio" checked={selectedAgeBand === idx} readOnly />
                        <div className="option-card-content">
                          <h4>{band.ageBandLabel}</h4>
                          <p>Monthly Premium: GH₵ {band.monthly.toFixed(2)} | Annual Premium: GH₵ {band.annual.toFixed(2)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="summary-right">
              <div className="summary-box plan-summary-box">
                <h2 className="summary-title">Your Plan Summary</h2>
                
                <div className="summary-row">
                  <span className="label">Selected Plan:</span>
                  <span className="value"><strong>{selectedPlan || 'N/A'}</strong></span>
                </div>
                <div className="summary-row">
                  <span className="label">Tier:</span>
                  <span className="value"><strong>{tierLabel || 'N/A'}</strong></span>
                </div>
                <div className="summary-row">
                  <span className="label">Age Group:</span>
                  <span className="value"><strong>{ageGroupLabel || 'N/A'}</strong></span>
                </div>
                <div className="summary-row">
                  <span className="label">Monthly Premium:</span>
                  <span className="value"><strong>GH₵ {totalMonthly.toFixed(2)}</strong></span>
                </div>
                <div className="summary-row">
                  <span className="label">Annual Premium:</span>
                  <span className="value"><strong>GH₵ {totalAnnual.toFixed(2)}</strong></span>
                </div>

                {canConfirm && (
                  <Link 
                    to={`/purchase?plan=${selectedPlan ? selectedPlan.toLowerCase() : ''}&tier=${tierNameForURL}&ageBandIndex=${selectedAgeBand}&monthly=${totalMonthly.toFixed(2)}&annual=${totalAnnual.toFixed(2)}`} 
                    className="select-button continue-btn"
                    style={{marginTop:'1rem', display:'inline-block'}}
                  >
                    Purchase Policy
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}

      </div>
      <Footer />
    </>
  );
};

export default CoverOptions;
