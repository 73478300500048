import React, { useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import { saveUserData } from '../services/databaseService';
import "./InsuranceForm.css";
import { initializePaystackTransaction } from '../services/paymentService';

const coverDetails = {
  1500: {
    title: "Silver Plan",
    price: "Gh₵ 1,500",
    features: [
      "Disability (Accident): Gh₵ 3,000",
      "Hospitalization Benefit: Gh₵ 50/day",
      "Monthly Premium: Gh₵ 4.32",
      "Annual Premium: Gh₵ 51.84",
    ],
    annualPremium: 51.84
  },
  2500: {
    title: "Gold Plan",
    price: "Gh₵ 2,500",
    features: [
      "Disability (Accident): Gh₵ 5,000",
      "Hospitalization Benefit: Gh₵ 100/day",
      "Monthly Premium: Gh₵ 8.07",
      "Annual Premium: Gh₵ 96.79",
    ],
    annualPremium: 96.79
  },
  5000: {
    title: "Platinum Plan",
    price: "Gh₵ 5,000",
    features: [
      "Disability (Accident): Gh₵ 10,000",
      "Hospitalization Benefit: Gh₵ 200/day",
      "Monthly Premium: Gh₵ 16.13",
      "Annual Premium: Gh₵ 193.58",
    ],
    annualPremium: 193.58
  },
};

const familyOptions = {
  family_option_1: [
    "Age band: 1-69yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 2.63 | Annual: Gh₵ 31.58",
    "Age band: 70-74yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 13.25 | Annual: Gh₵ 159.02",
    "Age band: 75-84yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 24.18 | Annual: Gh₵ 290.16",
  ],
  family_option_2: [
    "Age band: 1-69yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 5.26 | Annual: Gh₵ 63.15",
    "Age band: 70-74yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 26.50 | Annual: Gh₵ 318.03",
    "Age band: 75-84yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 48.36 | Annual: Gh₵ 580.32",
  ],
};

const GHACardMask = "GHA-XXXXXXXXX-X";

const ageGroups = ["1-69 years","70-74 years","75-84 years"];

// Parse monthly and annual from a family option line
function parseFamilyOptionLine(line) {
  const parts = line.split('|').map(p => p.trim());
  let monthlyVal = 0;
  let annualVal = 0;
  parts.forEach(part => {
    if (part.toLowerCase().includes('monthly')) {
      const val = part.split(':')[1].trim().replace('Gh₵','').trim();
      monthlyVal = parseFloat(val);
    } else if (part.toLowerCase().includes('annual')) {
      const val = part.split(':')[1].trim().replace('Gh₵','').trim();
      annualVal = parseFloat(val);
    }
  });
  return { monthlyVal, annualVal };
}

function parseCoverMainPremiums(features) {
  let mainMonthly = 0, mainAnnual = 0;
  features.forEach(f => {
    if (f.toLowerCase().includes('monthly premium')) {
      const val = f.split(':')[1].trim().replace('Gh₵','').trim();
      mainMonthly = parseFloat(val);
    } else if (f.toLowerCase().includes('annual premium')) {
      const val = f.split(':')[1].trim().replace('Gh₵','').trim();
      mainAnnual = parseFloat(val);
    }
  });
  return { mainMonthly, mainAnnual };
}

function InsuranceForm() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const plan = searchParams.get('plan'); // 'silver', 'gold', 'platinum'
  const tier = searchParams.get('tier'); // 'tier1' or 'tier2'
  const ageBandIndexParam = searchParams.get('ageBandIndex');

  let initialCoverOption = "";
  if (plan === 'silver') initialCoverOption = "1500";
  else if (plan === 'gold') initialCoverOption = "2500";
  else if (plan === 'platinum') initialCoverOption = "5000";

  let initialCoverDetailsOption = "";
  if (tier === 'tier1') initialCoverDetailsOption = 'family_option_1';
  else if (tier === 'tier2') initialCoverDetailsOption = 'family_option_2';

  let initialAgeIndex = 0; 
  if (!isNaN(ageBandIndexParam)) {
    const idx = parseInt(ageBandIndexParam, 10);
    if (idx >= 0 && idx <= 2) initialAgeIndex = idx;
  }

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    dateOfPayment: "",
    beneficiaryName: "",
    beneficiaryPhoneNumber: "",
    payeeGhanaCardNumber: "",
    beneficiaryGhanaCardNumber: "",
    coverOption: initialCoverOption,
    paymentMethod: "credit_card",
    coverDetailsOption: initialCoverDetailsOption,
    ageGroup: ageGroups[initialAgeIndex]
  });

  const [focusedInput, setFocusedInput] = useState(null);
  const [payeeTypedCount, setPayeeTypedCount] = useState(0);
  const [beneficiaryTypedCount, setBeneficiaryTypedCount] = useState(0);
  const [message, setMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(true); // for collapsible plan details

  // State for toggling just the main plan features
  const [featuresExpanded, setFeaturesExpanded] = useState(false);

  const payeeRef = useRef(null);
  const beneficiaryRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // If ageGroup changes, will recalc in calcPremiums
    setFormData({...formData, [name]: value});
  };

  const handlePhoneNumberChange = (e, fieldName) => {
    const val = e.target.value.replace(/\D/g, '');
    setFormData(prev => ({ ...prev, [fieldName]: val }));
  };

  const handleGhanaCardFocus = (e, fieldName, typedCountSetter) => {
    setFocusedInput(fieldName);
    if (!formData[fieldName]) {
      setFormData(prev => ({ ...prev, [fieldName]: GHACardMask }));
      setTimeout(() => {
        if (e.target.setSelectionRange) {
          e.target.setSelectionRange(4,4);
        }
      }, 0);
      typedCountSetter(0);
    }
  };

  const handleGhanaCardBlur = (e, fieldName, typedCount) => {
    setFocusedInput(null);
    if (formData[fieldName] === GHACardMask && typedCount === 0) {
      setFormData(prev => ({ ...prev, [fieldName]: "" }));
    }
  };

  const insertCharAt = (str, index, char) => str.slice(0, index) + char + str.slice(index+1);

  const handleGhanaCardKeyDown = (e, fieldName, typedCount, typedCountSetter) => {
    if (e.key === 'Backspace' && typedCount > 0) {
      e.preventDefault();
      let newVal = formData[fieldName];
      let removeIndex = typedCount === 10 ? 14 : (4 + (typedCount - 1));
      newVal = insertCharAt(newVal, removeIndex, 'X');
      setFormData(prev => ({ ...prev, [fieldName]: newVal }));
      typedCountSetter(typedCount - 1);

      setTimeout(() => {
        const input = (fieldName === 'payeeGhanaCardNumber') ? payeeRef.current : beneficiaryRef.current;
        if (input && input.setSelectionRange) {
          let cursorPos = typedCount === 10 ? 14 : (4 + (typedCount - 1));
          if (cursorPos < 4) cursorPos = 4;
          input.setSelectionRange(cursorPos, cursorPos);
        }
      }, 0);
    }
  };

  const handleGhanaCardChange = (e, fieldName, typedCount, typedCountSetter) => {
    const input = e.target;
    const oldVal = formData[fieldName];
    const newVal = input.value;

    if (!newVal.startsWith("GHA-")) {
      setFormData(prev => ({ ...prev }));
      setTimeout(() => {
        if (input.setSelectionRange) {
          input.setSelectionRange(4,4);
        }
      }, 0);
      return;
    }

    const selectionStart = input.selectionStart;
    const diff = newVal.length - oldVal.length;

    if (diff > 0) {
      const insertedChars = newVal.slice(selectionStart - diff, selectionStart);
      const validChars = insertedChars.replace(/[^A-Za-z0-9]/g, '');
      if (validChars.length === 0) {
        setFormData(prev => ({ ...prev }));
        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(4 + typedCount, 4 + typedCount);
          }
        }, 0);
        return;
      }
      const char = validChars[0];
      if (typedCount < 9) {
        let replaceIndex = 4 + typedCount; 
        let newStr = insertCharAt(oldVal, replaceIndex, char);
        setFormData(prev => ({ ...prev, [fieldName]: newStr }));
        typedCountSetter(typedCount + 1);

        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(4 + (typedCount + 1), 4 + (typedCount + 1));
          }
        }, 0);

      } else if (typedCount === 9) {
        let newStr = insertCharAt(oldVal, 14, char);
        setFormData(prev => ({ ...prev, [fieldName]: newStr }));
        typedCountSetter(typedCount + 1);

        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(15, 15);
          }
        }, 0);

      } else {
        setFormData(prev => ({ ...prev }));
        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(15, 15);
          }
        }, 0);
      }
    }
  };

  const shouldFloat = (fieldName) => {
    return focusedInput === fieldName || (formData[fieldName] && formData[fieldName] !== GHACardMask);
  };

  // Recalculate premiums based on current selections
  const calcPremiums = () => {
    if (!formData.coverOption) return { monthly: 0, annual: 0 };
    const main = coverDetails[formData.coverOption];
    if (!main) return { monthly: 0, annual: 0 };

    const { mainMonthly, mainAnnual } = parseCoverMainPremiums(main.features);

    if (!formData.coverDetailsOption || !formData.ageGroup) {
      return { monthly: mainMonthly, annual: mainAnnual };
    }

    const familyArr = familyOptions[formData.coverDetailsOption];
    if (!familyArr) return { monthly: mainMonthly, annual: mainAnnual };

    let index = ageGroups.indexOf(formData.ageGroup);
    if (index < 0) index = 0;
    const line = familyArr[index];
    const { monthlyVal, annualVal } = parseFamilyOptionLine(line);

    return {
      monthly: mainMonthly + monthlyVal,
      annual: mainAnnual + annualVal
    };
  };

  const { monthly, annual } = calcPremiums();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSave = {
      ...formData,
      submittedAt: new Date().toISOString()
    };

    let docId;
    try {
      docId = await saveUserData(dataToSave);
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('An error occurred. Please try again.');
      return;
    }

    setMessage(`Application submitted successfully! Reference ID: ${docId}`);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      dateOfBirth: "",
      dateOfPayment: "",
      beneficiaryName: "",
      beneficiaryPhoneNumber: "",
      payeeGhanaCardNumber: "",
      beneficiaryGhanaCardNumber: "",
      coverOption: "",
      paymentMethod: "credit_card",
      coverDetailsOption: "",
      ageGroup: ""
    });
  };

  const chosenPlan = formData.coverOption ? coverDetails[formData.coverOption] : null;



  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const chosenPlanFeatures = formData.coverOption ? coverDetails[formData.coverOption].features : [];

  return (
    <div className="insurance-form-page">
      <Header />
      <div className={`form-content ${formData.coverOption ? "slightly-shifted" : ""}`}>
        <div className="form-container">
          <div className="form-card">
            <h1>Nketewade Insurance</h1>
            <p>Please provide the following information to set up your insurance.</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required placeholder=" " />
                <label>First Name</label>
              </div>
              <div className="form-group">
                <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required placeholder=" " />
                <label>Last Name</label>
              </div>
              <div className="form-group">
                <input type="email" name="email" value={formData.email} onChange={handleChange} required placeholder=" " />
                <label>Email</label>
              </div>
              <div className="form-group">
                <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={(e) => handlePhoneNumberChange(e, 'phoneNumber')} required placeholder=" " maxLength="11" />
                <label>Phone Number</label>
              </div>
              <div className="form-group">
                <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} required placeholder="dd/mm/yyyy" />
                <label>Date of Birth</label>
              </div>
              <div className="form-group">
                <input type="date" name="dateOfPayment" value={formData.dateOfPayment} onChange={handleChange} required placeholder="dd/mm/yyyy" />
                <label>Preferred Payment Date</label>
              </div>
              <div className="form-group">
                <input type="text" name="beneficiaryName" value={formData.beneficiaryName} onChange={handleChange} required placeholder=" " />
                <label>Beneficiary Name</label>
              </div>
              <div className="form-group">
                <input type="tel" name="beneficiaryPhoneNumber" value={formData.beneficiaryPhoneNumber} onChange={(e) => handlePhoneNumberChange(e, 'beneficiaryPhoneNumber')} required placeholder=" " />
                <label>Beneficiary Phone Number</label>
              </div>

              <div className="form-group">
                <input
                  ref={payeeRef}
                  type="text"
                  name="payeeGhanaCardNumber"
                  value={formData.payeeGhanaCardNumber}
                  onFocus={(e) => handleGhanaCardFocus(e, 'payeeGhanaCardNumber', setPayeeTypedCount)}
                  onBlur={(e) => handleGhanaCardBlur(e, 'payeeGhanaCardNumber', payeeTypedCount)}
                  onKeyDown={(e) => handleGhanaCardKeyDown(e, 'payeeGhanaCardNumber', payeeTypedCount, setPayeeTypedCount)}
                  onChange={(e) => handleGhanaCardChange(e, 'payeeGhanaCardNumber', payeeTypedCount, setPayeeTypedCount)}
                  placeholder=" "
                />
                <label className={shouldFloat('payeeGhanaCardNumber') ? 'active' : ''}>Payee Ghana Card Number</label>
              </div>

              <div className="form-group">
                <input
                  ref={beneficiaryRef}
                  type="text"
                  name="beneficiaryGhanaCardNumber"
                  value={formData.beneficiaryGhanaCardNumber}
                  onFocus={(e) => handleGhanaCardFocus(e, 'beneficiaryGhanaCardNumber', setBeneficiaryTypedCount)}
                  onBlur={(e) => handleGhanaCardBlur(e, 'beneficiaryGhanaCardNumber', beneficiaryTypedCount)}
                  onKeyDown={(e) => handleGhanaCardKeyDown(e, 'beneficiaryGhanaCardNumber', beneficiaryTypedCount, setBeneficiaryTypedCount)}
                  onChange={(e) => handleGhanaCardChange(e, 'beneficiaryGhanaCardNumber', beneficiaryTypedCount, setBeneficiaryTypedCount)}
                  placeholder=" "
                />
                <label className={shouldFloat('beneficiaryGhanaCardNumber') ? 'active' : ''}>Beneficiary Ghana Card Number</label>
              </div>

              <div className="form-group cover-option-group">
                <select name="coverOption" value={formData.coverOption} onChange={handleChange} required>
                  <option value="" disabled>Select Cover Option</option>
                  <option value="1500">Silver</option>
                  <option value="2500">Gold</option>
                  <option value="5000">Platinum</option>
                </select>
                <label>Your Cover Option</label>
              </div>

              <div className="form-group cover-option-group">
                <select name="coverDetailsOption" value={formData.coverDetailsOption} onChange={handleChange} disabled={!formData.coverOption} required>
                  <option value="" disabled>Select Tier</option>
                  <option value="family_option_1">Tier 1</option>
                  <option value="family_option_2">Tier 2</option>
                </select>
                <label>Cover Option Details</label>
              </div>

              {/* Age Group as dropdown */}
              <div className="form-group">
                <select name="ageGroup" value={formData.ageGroup} onChange={handleChange}>
                  <option value="1-69 years">1-69 years</option>
                  <option value="70-74 years">70-74 years</option>
                  <option value="75-84 years">75-84 years</option>
                </select>
                <label>Benificiary Age Group</label>
              </div>

              <div className="payment-section">
                <h2>Payment Method</h2>
                <div className="payment-methods">
                  <label className={formData.paymentMethod === "credit_card" ? "active" : ""}>
                    <input type="radio" name="paymentMethod" value="credit_card" checked={formData.paymentMethod === "credit_card"} onChange={handleChange} />
                    <span className="icon">💳</span> Credit Card
                  </label>
                  <label className={formData.paymentMethod === "momo" ? "active" : ""}>
                    <input type="radio" name="paymentMethod" value="momo" checked={formData.paymentMethod === "momo"} onChange={handleChange} />
                    <span className="icon">📱</span> Mobile Money
                  </label>
                </div>
              </div>
              <button type="submit" className="submit-btn">
                Purchase Policy with {formData.paymentMethod === "credit_card" ? "Credit Card" : "Mobile Money"}
              </button>
            </form>
            {message && <p className="message">{message}</p>}
          </div>

          {/* Summary on the right side */}
          {formData.coverOption && (
  <div className="summary-right" style={{marginLeft:'20px'}}>
    <div className="summary-box plan-summary-box">
      <div className="summary-header" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <h2 className="summary-title" style={{margin:0}}>Your Plan Summary</h2>
      </div>
      <hr style={{margin:'10px 0'}}/>

      {chosenPlan && (
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'5px'}}>
          <p style={{fontSize:'0.9rem', color:'#000', marginBottom:'0'}}>
            <strong>Cover Option:</strong> {chosenPlan.title.replace(" Plan","")}
          </p>
          <span style={{fontSize:'1.2rem', fontWeight:'bold', cursor:'pointer'}} onClick={()=>setFeaturesExpanded(!featuresExpanded)}>
            {featuresExpanded ? '-' : '+'}
          </span>
        </div>
      )}

      {featuresExpanded && chosenPlan && (
        <ul style={{listStyle:'disc', paddingLeft:'20px', marginBottom:'10px'}}>
          {chosenPlan.features.map((f, i) => (
            <li key={i} style={{fontSize:'0.9rem', color:'#000', marginBottom:'5px'}}>{f}</li>
          ))}
        </ul>
      )}

      <hr style={{margin:'10px 0'}}/>
      {formData.coverDetailsOption && (
        <p style={{fontSize:'0.9rem', color:'#000', marginBottom:'5px'}}>
          <strong>Tier:</strong> {formData.coverDetailsOption === 'family_option_1' ? 'Tier 1' : 'Tier 2'}
        </p>
      )}
      {formData.ageGroup && (
        <p style={{fontSize:'0.9rem', color:'#000', marginBottom:'5px'}}>
          <strong>Benificiary Age Group:</strong> {formData.ageGroup}
        </p>
      )}
      <p style={{fontSize:'0.9rem', color:'#000', marginBottom:'5px'}}>
        <strong>Monthly Premium:</strong> GH₵ {monthly.toFixed(2)}
      </p>
      <p style={{fontSize:'0.9rem', color:'#000', marginBottom:'5px'}}>
        <strong>Annual Premium:</strong> GH₵ {annual.toFixed(2)}
      </p>
    </div>
  </div>
)}

              </div>
      </div>
      <Footer />
    </div>
  );
}

export default InsuranceForm;