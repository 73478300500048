export async function initializePaystackTransaction(email, amount, callbackUrl) {
  const response = await fetch("https://us-central1-nketewade-f5a98.cloudfunctions.net/initializeTransaction", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, amount, callbackUrl })
  });
  return response.json();
}

export async function verifyPaystackTransaction(reference) {
  const response = await fetch(`https://us-central1-nketewade-f5a98.cloudfunctions.net/verifyTransaction?reference=${reference}`);
  return response.json();
}
